import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from '../components/Button';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';

interface FormValues {
  email: string;
}

export const SendResetPasswordEmailPage = () => {
  const { login } = useAuth();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  return (
    <Section className="max-w-md">
      <form
        className="space-y-6"
        onSubmit={handleSubmit((data) => {
          setLoading(true);
          api
            .post(`/auth/request-password-reset`, data)
            .then(async () => {
              setLoading(false);
              alert('Email sent');
            })
            .catch((error) => {
              setLoading(false);
              alert(error.response.data.message);
            });
        })}
      >
        <H1>Find Password</H1>

        <TextField
          type="email"
          label="Email"
          placeholder="your@example.com"
          helper={errors.email?.message}
          {...register('email', { required: 'This field is required' })}
        />

        <div className="flex flex-col space-y-4 pt-10">
          <Button
            text={loading ? 'Loading...' : 'Send Email'}
            className="filled-brand-1"
            disabled={!watch('email') || loading}
          />
        </div>
      </form>
    </Section>
  );
};

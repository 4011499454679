import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Post } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { useQueryString } from '../../hooks';
import {
  postAdminH1,
  postHasDate,
  postHasDescription,
  postPath,
  usePostType,
} from '../../post-config';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';

export const PostList = () => {
  const postType = usePostType(true);
  const queryString = useQueryString({ filter: { type: postType } });
  const { data } = useQuery<Paginated<Post>>(
    `/admin/posts${queryString}`,
    fetcher,
    { keepPreviousData: true },
  );

  console.log('data---', data);

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between">
        <AdminH1>{postAdminH1(postType)}</AdminH1>
        <Button
          text="Add"
          to={`/admin/${postPath(postType)}/add`}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>title</Table.Th>
              {postHasDescription(postType) && <Table.Th>description</Table.Th>}
              {postHasDate(postType) && <Table.Th>date</Table.Th>}
              <Table.Th>created at</Table.Th>
              <Table.Th>priority</Table.Th>
              <Table.Th>isMainPost</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((post) => (
              <Table.Row key={post.id}>
                <Table.Td>{post.title}</Table.Td>
                {postHasDescription(postType) && (
                  <Table.Td>{post.description}</Table.Td>
                )}
                {postHasDate(postType) && (
                  <Table.Td>{post.dateString}</Table.Td>
                )}
                <Table.Td>
                  {utcToLocalFormat(post.createdAt, MomentFormat.LLL)}
                </Table.Td>
                <Table.Td>{post.priority}</Table.Td>
                <Table.Td>{post.isMainPost ? 'yes' : 'no'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/${postPath(postType)}/${post.id}`}
                  >
                    Details
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav
            basePath={`/admin/${postPath(postType)}`}
            total={data.total}
          />
        </Pagination>
      </Card>
    </>
  );
};

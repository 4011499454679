import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Role, User, UserType } from '../../types';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  role: string;
  type: string;
  exposed: boolean;
  firstName: string;
  lastName: string;
  password?: string;
  salutation: string;
  school: string;
  graduation: string;
  country: string;
  license: string;
  companyName: string;
  companyAddress: string;
  officeNumber: string;
  classYear: string;
  mobileNumber: string;
  languages: string;
  lat: string;
  lng: string;
}

export const UserEdit = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: user } = useQuery<User>(`/admin/users/${id}`, fetcher);
  const { reset, watch, register, setValue, handleSubmit } =
    useForm<FormValues>();
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) {
      reset(user);
      setLat(user.lat);
      setLng(user.lng);
    }
  }, [reset, user]);

  function _handleLatLng(e: any) {
    let latLng = e.target.value ?? ',';
    let [lat, lng] = latLng.replace(/\s/g, '').split(',');
    console.log(`lat: -${lat}-, -${lng}-`);
    setLat(lat ?? '');
    setLng(lng ?? '');
  }

  if (!user) return <></>;
  return (
    <>
      <AdminH1>User</AdminH1>

      <form
        onSubmit={handleSubmit((data) => {
          data.lat = lat;
          data.lng = lng;

          setIsLoading(true);
          api
            .patch(`/admin/users/${id}`, data)
            .then(() => {
              push(`/admin/users/${id}`);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        })}
      >
        <Card>
          <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-3 md:px-8">
            <TextField label="Email" type="email" value={user.email} disabled />
            {user.role !== Role.ADMIN && (
              <div>
                <Select label="Select" {...register('role')}>
                  <option label={Role.MEMBER} value={Role.MEMBER} />
                  <option label={Role.NON_MEMBER} value={Role.NON_MEMBER} />
                </Select>
                <p className="text-12 text-gray-500">
                  If you modify this value to MEMBER, an email will be sent to
                  this user.
                </p>
              </div>
            )}
            <Select label="Type" {...register('type')}>
              <option
                label={UserType.PROFESSIONAL}
                value={UserType.PROFESSIONAL}
              />
              <option label={UserType.STUDENT} value={UserType.STUDENT} />
            </Select>
            <TextField label="First Name" {...register('firstName')} />
            <TextField label="Last Name" {...register('lastName')} />
            <TextField
              label="Password"
              type="password"
              {...register('password')}
            />
            <TextField label="Salutation" {...register('salutation')} />
            <TextField label="School" {...register('school')} />
            {watch('type') === UserType.PROFESSIONAL && (
              <>
                <TextField label="Graduation" {...register('graduation')} />
                <TextField label="Country" {...register('country')} />
                <TextField label="License" {...register('license')} />
                <TextField label="Company Name" {...register('companyName')} />
                <div className="col-span-2">
                  <TextField
                    label="Company Address"
                    {...register('companyAddress')}
                  />
                </div>
                <TextField
                  label="Office Number"
                  {...register('officeNumber')}
                />
              </>
            )}
            {watch('type') === UserType.STUDENT && (
              <TextField label="Class Year" {...register('classYear')} />
            )}
            <TextField label="Mobile Number" {...register('mobileNumber')} />
            <TextField label="Languages" {...register('languages')} />
            <TextField
              label="Lat, Lng"
              value={`${lat}, ${lng}`}
              onChange={_handleLatLng}
            />
            <p>
              <a
                href="https://www.notion.so/IAKL-2eab3b84c64e44bd9bb0a48f2ebf91e4"
                target="_blank"
                rel="noreferrer"
              >
                위/경도 입력 가이드
              </a>
            </p>
          </div>

          <div className="px-4 py-6 sm:px-6 md:px-8">
            <div className="flex space-x-2">
              <span>Exposed</span>
              <Toggle
                checked={user.exposed}
                onChange={(checked) => setValue('exposed', checked)}
              />
              <p className="text-gray-400">
                {user.consentToExposure
                  ? '(User consent to exposure)'
                  : '(User declined to consent to exposure)'}
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            <Button
              type="button"
              text="Cancel"
              to={`/admin/users/${id}`}
              className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
            />
            <Button
              text={isLoading ? 'Loading...' : 'Save'}
              disabled={isLoading}
              className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
            />
          </div>
        </Card>
      </form>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as LogoVerticalIcon } from '../assets/svg/logo-1.svg';
import { ReactComponent as LogoIaklGlobalIcon } from '../assets/svg/iakl-global-icon.svg';

import { useAuth } from '../hooks';
import { languageState } from '../plugins/ridge';
import { Dropdown } from './Dropdown';
import { HamburgerButton } from './HamburgerButton';
import { ChevronDownIcon } from './icons';
import { Menu } from './Menu';
import { Section } from './Section';

export const TopNavBar = () => {
  const language = languageState.useValue();
  const history = useHistory();
  const { authenticated } = useAuth();
  const [dropdown, setDropdown] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [eventDropdown, setEventDropdown] = useState(false);
  const [resourcesDropdown, setResourcesDropdown] = useState(false);
  const [greetingDropdown, setGreetingDropdown] = useState(false);

  useEffect(() => {
    if (!dropdown) return;
    function close() {
      setDropdown(false);
      setAboutDropdown(false);
      setEventDropdown(false);
      setResourcesDropdown(false);
    }
    document.addEventListener('click', close);
    return () => document.removeEventListener('click', close);
  }, [dropdown]);

  return (
    <div className="border-b py-3 border-gray-100">
      <Section className=" md:justify-between flex justify-between items-center py-0 md:py-4 flex-row w-full bg-white">
        <nav className="flex items-center  text-gray-600 text-13 ">
          <button
            className="pr-4"
            onClick={() =>
              history.push(
                `${language === 'KO' ? '/greeting?local=KO' : '/?local=EN'}`,
              )
            }
          >
            <LogoVerticalIcon className="wh-12 md:wh-16" />
          </button>

          <div className="hidden md:flex space-x-7 md:items-center pr-4">
            {language === 'KO' ? (
              <>
                <Dropdown>
                  <button>소개</button>
                  <Dropdown.View className="w-36 z-10">
                    <Menu>
                      <Menu.Item text="인사말" to="/greeting?local=KO" />
                      <Menu.Item text="연혁도" to="/history?local=KO" />
                      <Menu.Item text="조직도" to="/organization?local=KO" />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>

                <button onClick={() => history.push('/seminars?local=KO')}>
                  총회 및 세미나
                </button>

                <button onClick={() => history.push('/notices?local=KO')}>
                  공지사항
                </button>
                <button onClick={() => history.push('/related-sites?local=KO')}>
                  관련 사이트
                </button>
              </>
            ) : (
              <>
                <Dropdown>
                  <button>About</button>
                  <Dropdown.View className="w-36 z-10">
                    <Menu>
                      <Menu.Item text="Who are we?" to="/who-are-we?local=EN" />
                      <Menu.Item text="Recent News" to="/recentnews?local=EN" />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>

                <button
                  onClick={() => history.push('/membership-directory?local=EN')}
                >
                  Membership
                </button>

                <button onClick={() => history.push('/jobs?local=EN')}>
                  Jobs
                </button>

                <Dropdown>
                  <button>Events</button>
                  <Dropdown.View className="w-40 z-10">
                    <Menu>
                      <Menu.Item
                        text="IAKL Conference"
                        to="/conferences?local=EN"
                      />
                      <Menu.Item text="Webinar" to="/webinars?local=EN" />
                      <Menu.Item
                        text="Social Functions"
                        to="/socialfunctions?local=EN"
                      />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>

                <button onClick={() => history.push('/pro-bono?local=EN')}>
                  Pro Bono
                </button>

                <button onClick={() => history.push('/committees?local=EN')}>
                  IAKL Committees
                </button>

                <button onClick={() => history.push('/sponsorship?local=EN')}>
                  Sponsorship
                </button>

                <Dropdown>
                  <button>Resources</button>
                  <Dropdown.View className="w-32 z-10">
                    <Menu>
                      <Menu.Item text="Newsletter" to="/newsletters?local=EN" />
                      <Menu.Item
                        text="Publications"
                        to="/publications?local=EN"
                      />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>

                <button onClick={() => history.push('/gallery?local=EN')}>
                  Gallery
                </button>
              </>
            )}
          </div>
        </nav>

        <div
          className="md:hidden ounded-md  mr-4 ml-auto inline-flex items-center px-4 py-2 bg-gray-900 text-white rounded-lg"
          onClick={() => {
            window.location.href = `https://www.iakl.org/`;
          }}
        >
          <span className="mr-2 text-12">iakl.org</span>
          <LogoIaklGlobalIcon className=" text-white rounded-md" />
        </div>
        <HamburgerButton
          className="md:hidden"
          open={dropdown}
          onClick={() => setDropdown(!dropdown)}
        />

        <div className="hidden md:flex space-x-3 items-center text-13">
          <div
            className="inline-flex items-center px-4 py-2 bg-gray-900 text-white rounded-lg"
            onClick={() => {
              window.location.href = `https://www.iakl.org/`;
            }}
          >
            <span className="mr-2">iakl.org</span>

            <LogoIaklGlobalIcon className=" text-white rounded-md" />
          </div>

          {authenticated ? (
            <>
              <button
                onClick={() => history.push('/mypage')}
                className="text-13 bg-brand-1 text-white rounded-md px-4 py-2"
              >
                My Page
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => history.push('/signin')}
                className="bg-brand-1 text-white rounded-md px-4 py-2"
              >
                Sign in
              </button>
            </>
          )}

          <button
            onClick={() => {
              if (language === 'EN') {
                languageState.set('KO');
                history.push('/greeting?local=KO');
              } else {
                languageState.set('EN');
                history.push('/?local=EN');
              }
            }}
            className="text-13 rounded-md px-4 py-2 bg-gray-100"
          >
            {language === 'KO' ? 'EN' : 'KO'}
          </button>
        </div>
      </Section>

      {/* Mobile TopNavBar Start */}
      <div
        className={`md:hidden z-50 absolute w-full space-y-5 text-14 pt-4 px-6 pb-6 bg-white flex flex-col transition transform ${
          dropdown
            ? 'ease-in-out duration-500 opacity-100 translate-y-0'
            : 'ease-in duration-250 opacity-0 pointer-events-none -translate-y-5'
        }`}
      >
        {language === 'KO' ? (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setGreetingDropdown(!greetingDropdown);
              }}
              className={`flex space-x-3 items-center`}
            >
              <p>소개</p>
              <ChevronDownIcon
                className={`w-4 transform transition duration-100 ease-in ${
                  greetingDropdown ? '-rotate-180' : 'rotate-0'
                }`}
              />
            </div>
            <div
              className={`bg-gray-50 rounded-md flex flex-col space-y-6 
          px-4 py-6 transform transition ${
            greetingDropdown
              ? 'opacity-100 scale-y-100 '
              : 'hidden scale-y-0 opacity-0'
          }`}
            >
              <Link to="/greeting?local=KO">인사말</Link>
              <Link to="/history?local=KO">연혁도</Link>
              <Link to="/organization?local=KO">조직도</Link>
            </div>

            <Link to="/seminars?local=KO" className="">
              총회 및 세미나
            </Link>

            <Link to="/notices?local=KO" className="">
              공지사항
            </Link>
            <Link to="/related-sites?local=KO" className="">
              관련 사이트
            </Link>
          </>
        ) : (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setAboutDropdown(!aboutDropdown);
              }}
              className={`flex space-x-3 items-center`}
            >
              <p>About</p>
              <ChevronDownIcon
                className={`w-4 transform transition duration-100 ease-in ${
                  aboutDropdown ? '-rotate-180' : 'rotate-0'
                }`}
              />
            </div>
            <div
              className={`bg-gray-50 rounded-md flex flex-col space-y-6 
          px-4 py-6 transform transition ${
            aboutDropdown
              ? 'opacity-100 scale-y-100 '
              : 'hidden scale-y-0 opacity-0'
          }`}
            >
              <Link to="/who-are-we?local=EN">Who are we?</Link>
              <Link to="/recentnews?local=EN">Recent News</Link>
            </div>
            <Link to="/membership-directory?local=EN" className="">
              Membership
            </Link>
            <Link to="/jobs?local=EN" className="">
              Jobs
            </Link>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setEventDropdown(!eventDropdown);
              }}
              className={`flex space-x-3 items-center`}
            >
              <p>Events</p>
              <ChevronDownIcon
                className={`w-4 transform transition duration-100 ease-in ${
                  eventDropdown ? '-rotate-180' : 'rotate-0'
                }`}
              />
            </div>
            <div
              className={`bg-gray-50 rounded-md  flex flex-col space-y-6 
          px-4 py-6 transform transition ${
            eventDropdown
              ? 'opacity-100 scale-y-100 '
              : 'hidden scale-y-0 opacity-0'
          }`}
            >
              <Link to="/conferences?local=EN">IAKL Conference</Link>
              <Link to="/webinars?local=EN">Webinar</Link>
              <Link to="/socialfunctions?local=EN">Social Functions</Link>
            </div>
            <Link to="/pro-bono?local=EN" className="">
              Pro Bono
            </Link>
            <Link to="/committees?local=EN" className="">
              IAKL Committees
            </Link>
            <Link to="/sponsorship?local=EN" className="">
              Sponsorship
            </Link>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setResourcesDropdown(!resourcesDropdown);
              }}
              className={`flex space-x-3 items-center`}
            >
              <p>Resources</p>
              <ChevronDownIcon
                className={`w-4 transform transition duration-100 ease-in ${
                  resourcesDropdown ? '-rotate-180' : 'rotate-0'
                }`}
              />
            </div>
            <div
              className={`bg-gray-50 rounded-md flex flex-col space-y-6 
          px-4 py-6 transform transition ${
            resourcesDropdown
              ? 'opacity-100 scale-y-100 '
              : 'hidden scale-y-0 opacity-0'
          }`}
            >
              <Link to="/newsletters?local=EN">Newsletter</Link>
              <Link to="/publications?local=EN">Publications</Link>
            </div>
            <Link to="/gallery?local=EN" className="">
              Gallery
            </Link>
          </>
        )}

        {authenticated ? (
          <Link to="/mypage" className="">
            My Page
          </Link>
        ) : (
          <Link to="/signin" className="">
            Join us / Sign in
          </Link>
        )}
        <button
          onClick={() => {
            if (language === 'EN') {
              languageState.set('KO');
              history.push('/greeting?local=KO');
            } else {
              languageState.set('EN');
              history.push('/?local=EN');
            }
          }}
          className={`flex space-x-3 items-center rounded-md w-10 h-6 justify-center bg-gray-100`}
        >
          {language === 'KO' ? 'EN' : 'KO'}
        </button>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import {
  postHasContent,
  postHasDate,
  postPath,
  memberOnlyPost,
} from '../post-config';
import { Post, PostType, Role, User } from '../types';
import { Button } from './Button';
import { ClockIcon } from './icons';

export interface GalleryCardProps {
  post: Post;
}

export const GalleryCard: FC<GalleryCardProps> = ({ post }) => {
  const { push } = useHistory();
  const { authenticated } = useAuth();

  async function accessDenied() {
    if (!memberOnlyPost(post.type)) return false;
    if (!authenticated) return true;
    const { data: me } = await api.get<User>('/users/me');
    return me.role === Role.NON_MEMBER;
  }

  return (
    <button
      className="flex items-center space-x-5 text-left"
      onClick={async () => {
        if (await accessDenied()) return alert('Member Only');
        postHasContent(post.type)
          ? push(`/${postPath(post.type)}/${post.id}?local=EN`)
          : window.open(post.url, '_blank');
      }}
    >
      {post.thumbnail ? (
        <img
          src={post.thumbnail}
          alt={post.title}
          className="w-40 h-40 flex-shrink-0 rounded-lg object-cover bg-gray-200 "
        />
      ) : (
        <div
          className={`grid place-items-center mb-3 w-full rounded-lg bg-gray-200 font-medium text-48 text-white ${'h-44'}`}
        >
          Image
        </div>
      )}

      <div className="flex-col flex md:space-y-4 space-y-2">
        <p className="flex space-x-1 pt-1 max-w-full md:text-13 text-gray-500 text-12">
          {postHasDate(post.type) ? (
            <>
              <ClockIcon className="wh-4" />
              <time className="flex-1 truncate">{post.dateString}</time>
            </>
          ) : (
            <time>{utcToLocalFormat(post.createdAt, MomentFormat.LL)}</time>
          )}
        </p>

        <div className="md:space-y-2 space-y-1">
          <h1 className="flex-1 md:text-20 text-15 font-bold truncate-2-lines break-all">
            {post.title}
          </h1>
          <p className="md:text-14 text-gray-800 truncate-2-lines text-13">
            2020 IAKL Annual Conference ‘Beyond Korean Heritage’2020 IAKL Annual
            Conference ‘Beyond Korean Heritage’2020 IAKL Annual Conference
            ‘Beyond Korean Heritage’2020 IAKL Annual Conference
          </p>
        </div>
      </div>

      {post.type === PostType.WEBINAR && post.url && (
        <Button
          onClick={() => {
            window.open(post.url, '_blank');
          }}
          text="Watch Webinar"
          className="mt-4 w-full h-10 outlined-brand-1"
        />
      )}
    </button>
  );
};

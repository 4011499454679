import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import { Labeled } from './Labeled';

export interface ImageUploadProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  file: File | null;
}

export const ImageUplaod: FC<ImageUploadProps> = ({
  label,
  file,
  ...props
}) => {
  const [id] = useId(1, 'fileupload');
  const [preview, setPreview] = useState<string | null>(null);
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
    } else {
      setPreview(null);
    }
  }, [file]);

  return (
    <Labeled label={label}>
      <label
        htmlFor={id}
        className="inline-flex items-center p-2 cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
      >
        <span>Upload an image</span>
        <input
          id={id}
          type="file"
          accept="image/*"
          className="sr-only"
          {...props}
        />
      </label>
      {preview && <img src={preview} alt="preview" className="max-w-xs mt-4" />}
    </Labeled>
  );
};

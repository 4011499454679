import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { PostCard } from '../components/PostCard';
import { Tab } from '../components/Tab';
import { Search } from '../components/Search';
import { useQueryString } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { postH1, postPath, usePostType } from '../post-config';
import { Paginated, Post, PostType } from '../types';
import { Section } from '../components/Section';
import { H1 } from '../components/H1';
import { Tabs } from '../components/Tabs';
import { JobCard } from '../components/JobCard';
import { Pagination } from '../components/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { GalleryCard } from '../components/GalleryCard';
import { RecentGalleryCard } from '../components/RecentGalleryCard';

export const PostsPage = () => {
  const postType = usePostType();
  const limit = 10000;
  const [ilike, setIlike] = useState('');
  const queryString = useQueryString({
    limit,
    filter: { type: postType, title: { ilike } },
  });
  const { data } = useQuery<Paginated<Post>>(`/posts${queryString}`, fetcher, {
    keepPreviousData: true,
  });

  if (!data) return <></>;

  const items = data.items;

  let selectedItem = items.find((item) => item.isMainPost === true);

  if (!selectedItem && items) {
    // selectedItem = items[0];
  }

  const filteredItems = items?.filter((item) => item !== selectedItem);
  return (
    <Section>
      <div className="flex flex-col items-center space-y-8 mb-14">
        <H1>{postH1(postType)}</H1>
        {isEventPost(postType) && <PostTabs tabs={eventTabs} />}
        {isResourcePost(postType) && <PostTabs tabs={resourceTabs} />}
        {postType === PostType.GALLERY && (
          <div className="w-full md:w-1/2">
            <Search
              placeholder="Search"
              onChange={(e) => setIlike(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className="grid xl:grid-cols-3 items-start xl:divide-x-2 divide-y-2 xl:divide-y-0 divide-gray-200 w-full max-w-screen-xl xl:gap-10 grid-cols-1 gap-4">
        {selectedItem && <RecentGalleryCard post={selectedItem} />}
        <div className="md:pt-0 xl:col-span-2 space-y-5 xl:pl-10 col-span-1 pl-0 pt-4">
          {filteredItems
            ?.sort((a: Post, b: Post) => b.priority - a.priority)
            .map((post) => (
              <GalleryCard key={post.id} post={post} />
            ))}
        </div>
      </div>

      {/* <Pagination className="justify-center mt-8 p-0">
        <Pagination.Nav
          basePath={`/${postPath(postType)}`}
          local={'EN'}
          total={data.total}
          maxPageSetLength={5}
          defaultLimit={limit}
        />
      </Pagination> */}
    </Section>
  );
};

function isEventPost(postType: PostType) {
  return [
    PostType.CONFERENCE,
    PostType.WEBINAR,
    PostType.SOCIAL_FUNCTIONS,
  ].includes(postType);
}
function isResourcePost(postType: PostType) {
  return [PostType.NEWS_LETTER, PostType.PUBLICATIONS].includes(postType);
}

const eventTabs = [
  {
    name: 'IAKL Conference',
    path: '/conferences?local=EN',
    pathname: '/conferences',
  },
  { name: 'IAKL Webinar', path: '/webinars?local=EN', pathname: '/webinars' },
  {
    name: 'Social Functions',
    path: '/socialfunctions?local=EN',
    pathname: '/socialfunctions',
  },
];

const resourceTabs = [
  {
    name: 'IAKL Newsletter',
    path: '/newsletters?local=EN',
    pathname: '/newsletters',
  },
  {
    name: 'Publications',
    path: '/publications?local=EN',
    pathname: '/publications',
  },
];

const PostTabs: FC<{ tabs: any[] }> = ({ tabs }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <Tabs>
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.name}
            name={tab.name}
            selected={pathname.startsWith(tab.pathname)}
            onClick={() => push(tab.path)}
          />
        );
      })}
    </Tabs>
  );
};

import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { EditorView } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { MainPopup, Seminar } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const MainPopupsShow = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const { data: mainPopup } = useQuery<MainPopup>(
    `/admin/main-popups/${id}`,
    fetcher,
    {
      enabled: !!id,
    },
  );

  const remove = async () => {
    await api.delete(`/admin/main-popups/${id}`);
    push(`/admin/main/popups`);
  };

  if (!mainPopup) return <></>;
  return (
    <>
      <AdminH1>MainPopups Details</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="Title"
            type="email"
            value={mainPopup.title}
            disabled
          />
          <TextField
            label="RedirectUrl"
            type="email"
            value={mainPopup.redirectUrl}
            disabled
          />
          <TextField
            label="Priority"
            type="email"
            value={mainPopup.priority}
            disabled
          />

          <Labeled label="Thumbnail">
            <img
              src={mainPopup.filename}
              alt="thumbnail"
              className="max-w-xs"
            />
          </Labeled>

          {/* <Labeled label="Content">
            <div className="p-4 rounded-xl border border-gray-200">
              <EditorView model={mainPopup.context} />
            </div>
          </Labeled> */}
        </div>
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Delete"
            className="h-10 text-sm filled-red-500 hover:bg-red-600"
            onClick={() => window.confirm('Delete this item?') && remove()}
          />
          <Button
            text="Edit"
            to={`/admin/main/popups/${id}/edit`}
            className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
          />
        </div>
        <div className="px-4 py-6 sm:px-6 md:px-8">
          <div className="flex space-x-2">
            <Toggle checked={mainPopup.isHide} disabled />
            <p className="text-gray-400">숨김 활성화 여부</p>
          </div>
        </div>
      </Card>
    </>
  );
};

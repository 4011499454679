import React, { FC, useState } from 'react';

import { XIcon } from './icons';

import { Overlay } from './Overlay';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

interface PracticeAreaPopupProps {
  open: boolean;
  onClose: () => void;
}

export const TermsOfServiceConsentPopup: FC<PracticeAreaPopupProps> = ({
  open,
  onClose,
}) => {
  const [isKorean, setIsKorean] = useState(false);

  const docs = [
    {
      uri: isKorean
        ? 'https://iakl.s3.us-west-2.amazonaws.com/iakl/TermsOfServiceConsentKr.docx'
        : 'https://iakl.s3.us-west-2.amazonaws.com/iakl/TermsOfServiceConsentEn.docx',
      fileType: 'docx',
    },
  ];

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />

      <div className="relative flex flex-col space-y-4 max-w-4/5 max-h-4/5 shadow-lg rounded-lg bg-white overflow-hidden w-full h-full">
        <div className="flex justify-between p-8">
          <h1 className="text-xs sm:text-lg font-bold text-left sm:text-center">
            {isKorean ? 'IAKL홈페이지 이용약관' : 'Terms of Service'}
          </h1>
          {/* <h1 className="text-2xl font-bold text-center">Terms of Service for the International Association of Korean Lawyers (IAKL)</h1> */}

          <div className="flex-1 flex justify-end space-x-4">
            <button
              type="button"
              className=" sm:px-4 sm:py-2 sm:text-base px-4 py-2 border-2 border-black bg-transparent text-black rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={() => setIsKorean(!isKorean)}
            >
              {'KR / EN'}
            </button>
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          style={{ width: '100%', height: `100%` }}
          config={{ header: { disableHeader: true } }}
        />

        {/* <div className="grid grid-cols-1 md:grid-cols-1 whitespace-normal gap-4 px-8 text-lg overflow-y-auto">
          <div dangerouslySetInnerHTML={{ __html: krContent }} />
        </div> */}
      </div>
    </div>
  );
};

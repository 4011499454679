import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { EditorView } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import {
  postAdminH1,
  postHasContent,
  postHasDate,
  postHasDescription,
  postHasPriority,
  postHasSubType,
  postHasThumbnail,
  postHasUrl,
  postPath,
  usePostType,
} from '../../post-config';
import { Post } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { Toggle } from '../../components/Toggle';

export const PostShow = () => {
  const { push } = useHistory();
  const postType = usePostType(true);
  const { id } = useParams<{ id: string }>();
  const { data: post } = useQuery<Post>(`/admin/posts/${id}`, fetcher);

  async function remove() {
    await api.delete(`/admin/posts/${id}`);
    push(`/admin/${postPath(postType)}`);
  }

  if (!post) return <></>;
  return (
    <>
      <AdminH1>{postAdminH1(postType)}</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          {postHasSubType(postType) && (
            <TextField label="Type" value={post.subType} disabled />
          )}
          <TextField label="Title" value={post.title} disabled />

          {postHasPriority(postType) && (
            <TextField label="Priority" value={post.priority} disabled />
          )}
          {postHasDescription(postType) && (
            <TextField label="Description" value={post.description} disabled />
          )}
          {postHasUrl(postType) && (
            <TextField label="URL" value={post.url} disabled />
          )}
          {postHasDate(postType) && (
            <TextField label="Date" value={post.dateString} disabled />
          )}
          {postHasThumbnail(postType) && (
            <Labeled label="Thumbnail">
              <img src={post.thumbnail} alt="thumbnail" className="max-w-xs" />
            </Labeled>
          )}
          {postHasContent(postType) && (
            <Labeled label="Content">
              <div className="p-4 rounded-xl border border-gray-200">
                <EditorView model={post.content} />
              </div>
            </Labeled>
          )}
          <div className="flex space-x-2 ">
            <Toggle checked={post.isMainPost} disabled={true} />
            <p className="text-gray-400">대표 게시글 여부</p>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Delete"
            className="h-10 text-sm filled-red-500 hover:bg-red-600"
            onClick={() => window.confirm('Delete this item?') && remove()}
          />
          <Button
            text="Edit"
            to={`/admin/${postPath(postType)}/${id}/edit`}
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
          />
        </div>
      </Card>
    </>
  );
};

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { PostList } from './pages/PostList';
import { UserShow } from './pages/UserShow';
import { UsersList } from './pages/UserList';
import { PostShow } from './pages/PostShow';
import { PostAdd } from './pages/PostAdd';
import { PostEdit } from './pages/PostEdit';
import { postAdminH1, postPath, postPaths } from '../post-config';
import { UserEdit } from './pages/UserEdit';
import { PostType } from '../types';
import { NoticeAdd } from './pages/NoticeAdd';
import { NoticeEdit } from './pages/NoticeEdit';
import { NoticeShow } from './pages/NoticeShow';
import { NoticeList } from './pages/NoticeList';
import { SeminarsList } from './pages/SeminarLIst';
import { SeminarsShow } from './pages/SeminarShow';
import { SeminarsEdit } from './pages/SeminarEdit';
import { SeminarsAdd } from './pages/SeminarAdd';

import { MainPopupsAdd } from './pages/MainPopupAdd';
import { MainPopupsShow } from './pages/MainPopupShow';

import { MainPopupsEdit } from './pages/MainPopupEdit';
import { MainPopupsList } from './pages/MainPopupList';

export const Admin = () => {
  return (
    <div className="h-screen flex">
      <Sidebar>
        <Sidebar.Title>IAKL</Sidebar.Title>
        <Sidebar.Menu>
          <Sidebar.Menu.Item text="Users" to="/admin/users" />
          {Object.values(PostType).map((postType) => (
            <Sidebar.Menu.Item
              text={postAdminH1(postType)}
              to={`/admin/${postPath(postType)}`}
            />
          ))}
          <Sidebar.Menu.Item text="Notices" to="/admin/notices" />
          <Sidebar.Menu.Item text="Seminars" to="/admin/seminars" />
          <Sidebar.Menu.Item text="MainPopups" to="/admin/main/popups" />
        </Sidebar.Menu>
      </Sidebar>

      <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
        <AdminHeader />
        <AdminMain>
          <Switch>
            <Route path="/admin/notices/add" component={NoticeAdd} />
            <Route path="/admin/notices/:id/edit" component={NoticeEdit} />
            <Route path="/admin/notices/:id" component={NoticeShow} />
            <Route path="/admin/notices" component={NoticeList} />

            <Route path="/admin/seminars/add" component={SeminarsAdd} />
            <Route path="/admin/seminars/:id/edit" component={SeminarsEdit} />
            <Route path="/admin/seminars/:id" component={SeminarsShow} />
            <Route path="/admin/seminars" component={SeminarsList} />

            <Route path="/admin/users/:id/edit" component={UserEdit} />
            <Route path="/admin/users/:id" component={UserShow} />
            <Route path="/admin/users" component={UsersList} />

            <Route path="/admin/main/popups/add" component={MainPopupsAdd} />
            <Route
              path="/admin/main/popups/:id/edit"
              component={MainPopupsEdit}
            />
            <Route path="/admin/main/popups/:id" component={MainPopupsShow} />
            <Route path="/admin/main/popups" component={MainPopupsList} />

            <Route
              path={postPaths.map((path) => `/admin/${path}/add`)}
              component={PostAdd}
            />
            <Route
              path={postPaths.map((path) => `/admin/${path}/:id/edit`)}
              component={PostEdit}
            />
            <Route
              path={postPaths.map((path) => `/admin/${path}/:id`)}
              component={PostShow}
            />
            <Route
              path={postPaths.map((path) => `/admin/${path}`)}
              component={PostList}
            />

            <Route path="/">
              <Redirect to="/admin/users" />
            </Route>
          </Switch>
        </AdminMain>
      </div>
    </div>
  );
};

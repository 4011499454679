import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../components/Button';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';

interface FormValues {
  email: string;
  password: string;
}

export const SignInPage = () => {
  const { login } = useAuth();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <Section className="max-w-md">
      <form
        className="space-y-6"
        onSubmit={handleSubmit((data) => login(data))}
      >
        <H1>Sign In</H1>

        <TextField
          type="email"
          label="Email"
          placeholder="your@example.com"
          helper={errors.email?.message}
          {...register('email', { required: 'This field is required' })}
        />
        <TextField
          type="password"
          label="Password"
          placeholder="********"
          helper={errors.password?.message}
          {...register('password', {
            required: 'This field is required',
            minLength: {
              value: 6,
              message: 'Please enter at least 6 characters',
            },
            maxLength: {
              value: 15,
              message: 'Password length must be 15 or less',
            },
          })}
        />

        <div className="flex flex-col space-y-4 pt-10">
          <Button
            text="Sign In"
            className="filled-brand-1"
            disabled={!watch('email') || !watch('password')}
          />
          <Button text="Join now" to="/joinus/1" className="outlined-brand-1" />
          <Button
            text="ResetPassword"
            to="/find/password"
            className="outlined-brand-1"
          />
        </div>
      </form>
    </Section>
  );
};

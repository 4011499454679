import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { ImageUplaod } from '../../components/ImageUpload';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { Editor } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { CreateMainPopup, MainPopup } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const MainPopupsEdit = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [title, setTitle] = useState('');
  const [priority, setPriority] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>('');
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const { data: mainPopup, refetch } = useQuery<MainPopup>(
    `/admin/main-popups/${id}`,
    fetcher,
    {
      enabled: !!id,
    },
  );
  const [isHide, setHide] = useState(mainPopup ? mainPopup.isHide : false);

  async function save() {
    const patchMainPopupData: CreateMainPopup = {
      title,
      isHide,
      redirectUrl,
      priority,
    };

    if (thumbnail) {
      const formData = new FormData();
      formData.append('file', thumbnail);
      const { data: url } = await api.post('/s3/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      patchMainPopupData.filename = url;
    } else {
      patchMainPopupData.filename = mainPopup?.filename ?? '';
    }

    await api.patch(`/admin/main-popups/${id}`, patchMainPopupData);
    await refetch();
    push(`/admin/main/popups/${id}`);
  }

  useEffect(() => {
    if (mainPopup) {
      setTitle(mainPopup.title);
      setHide(mainPopup.isHide);
      setPriority(mainPopup.priority);
      setRedirectUrl(mainPopup.redirectUrl);
    }
  }, [mainPopup]);
  if (!mainPopup) return <></>;
  return (
    <>
      <AdminH1>MainPopups</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Redricet Url"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
          />
          <TextField
            type="number"
            label="Priority"
            value={priority}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value >= 0) {
                setPriority(value);
              }
            }}
          />
          <ImageUplaod
            label="Thumbnail (1:1비율, 2MB 이하, 650px * 650px)"
            file={thumbnail}
            onChange={(e) =>
              e.target.validity.valid &&
              e.target.files &&
              setThumbnail(e.target.files.item(0))
            }
          />

          <div className="flex space-x-2 ">
            <Toggle onChange={() => setHide(!isHide)} checked={isHide} />
            <p className="text-gray-400">숨김 활성화 여부</p>
          </div>
        </div>
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            type="button"
            text="Cancel"
            to={`/admin/main/popups/${id}`}
            className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
          />
          <Button
            text="Save"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            disabled={!title}
            onClick={save}
          />
        </div>
      </Card>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { CommitteePopup } from '../components/CommitteePopup';
import { PracticeAreaPopup } from '../components/PracticeAreaPopup';
import { Select } from '../components/Select';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { fetcher } from '../plugins/react-query';
import {
  countries,
  salutationOthers,
  salutations,
  User,
  UserType,
} from '../types';

interface FormValues {
  firstName: string;
  lastName: string;
  salutation: string;
  otherSalutation: string;
  graduation: string;
  practiceAreas: string[];
  country: string;
  license: string;
  companyName: string;
  companyAddress: string;
  officeNumber: string;
  committees: string[];
  consentToExposure: boolean;
  school: string;
  classYear: string;
  mobileNumber: string;
  languages: string;
}

export const MyPage = () => {
  const { logout } = useAuth();
  const { push } = useHistory();
  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { practiceAreas: [], committees: [] },
  });
  const { data: me } = useQuery<User>('/users/me', fetcher);
  useEffect(() => {
    if (!me) return;
    const salutation =
      me && salutations.includes(me.salutation)
        ? me.salutation
        : salutationOthers;
    const otherSalutation = me?.salutation;
    reset({ ...me, salutation, otherSalutation });
  }, [reset, me]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  if (!me) return <></>;
  return (
    <>
      <PracticeAreaPopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        value={watch('practiceAreas')}
        onSave={(value) => setValue('practiceAreas', value)}
      />
      <CommitteePopup
        open={modalOpen2}
        onClose={() => setModalOpen2(false)}
        value={watch('committees')}
        onSave={(value) => setValue('committees', value)}
      />

      <div className="w-full space-y-6 pt-10 md:pt-20 pb-40 max-w-md mx-auto px-4">
        <h1 className="text-32 font-bold text-center pb-7 md:pb-14">My Page</h1>
        <div className="w-full space-y-8 flex flex-col justify-center">
          {me.type === UserType.PROFESSIONAL && (
            <>
              {/* <TextField
                label="Password"
                placeholder="Enter your password here."
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> */}

              <TextField
                label="First Name*"
                type="text"
                helper={errors.firstName?.message}
                {...register('firstName', {
                  required: 'This field is required',
                })}
              />

              <TextField
                label="Last Name*"
                type="text"
                helper={errors.lastName?.message}
                {...register('lastName', {
                  required: 'This field is required',
                })}
              />

              <div className="flex flex-col space-y-2">
                <Select
                  label="How would you like to be addressed?"
                  placeholder="Select"
                  value={watch('salutation')}
                  onChange={(e) => setValue('salutation', e.target.value)}
                >
                  {salutations.map((s) => (
                    <option key={s}>{s}</option>
                  ))}
                  <option>{salutationOthers}</option>
                </Select>

                {watch('salutation') === salutationOthers && (
                  <TextField
                    placeholder="Please enter your preferred salutation here."
                    type="text"
                    value={watch('otherSalutation')}
                    onChange={(e) =>
                      setValue('otherSalutation', e.target.value)
                    }
                  />
                )}
              </div>

              <div className="flex flex-col space-y-3">
                <TextField
                  label="Where did you study law?"
                  placeholder="Name of School"
                  helper={errors.school?.message}
                  {...register('school', {
                    required: 'This field is required',
                  })}
                />
                <TextField
                  placeholder="Year of Graduation"
                  helper={errors.graduation?.message}
                  {...register('graduation', {
                    required: 'This field is required',
                  })}
                />
              </div>

              <div>
                <div className="flex justify-between items-center">
                  <h2 className="text-14 font-bold">
                    Practice Area/Speciality*
                  </h2>
                  <button
                    onClick={() => setModalOpen(true)}
                    className="text-13 bg-gray-100 px-4 py-2 rounded-md"
                  >
                    Select
                  </button>
                </div>

                {watch('practiceAreas').length > 0 ? (
                  watch('practiceAreas').map((practiceArea) => (
                    <p
                      key={practiceArea}
                      className="text-gray-600 text-15 pt-4"
                    >
                      {practiceArea}
                    </p>
                  ))
                ) : (
                  <p className="text-gray-300">No options selected.</p>
                )}
              </div>

              <Select
                label="Country/Jurisdiction*"
                placeholder="Select"
                helper={errors.country?.message}
                {...register('country', { required: 'This field is required' })}
              >
                {countries.map((country) => (
                  <option
                    key={`${country.name}${country.code}${country.abbreviation}`}
                    value={country.name}
                    disabled={!!country.disabled}
                  >
                    {`${country.abbreviation ? ' - ' : ''}${country.name}`}
                  </option>
                ))}
              </Select>

              <TextField
                label="License number*"
                helper={errors.license?.message}
                {...register('license', { required: 'This field is required' })}
              />

              <div className="flex flex-col space-y-3">
                <TextField
                  label="Company/Law Firm*"
                  placeholder="Name"
                  helper={errors.companyName?.message}
                  {...register('companyName', {
                    required: 'This field is required',
                  })}
                />
                <TextField
                  placeholder="Address"
                  helper={errors.companyAddress?.message}
                  {...register('companyAddress', {
                    required: 'This field is required',
                  })}
                />
              </div>

              <TextField
                label="Office phone number*"
                helper={errors.officeNumber?.message}
                {...register('officeNumber', {
                  required: 'This field is required',
                })}
              />

              <TextField
                label="Mobile number"
                helper={errors.mobileNumber?.message}
                {...register('mobileNumber')}
              />

              <TextField
                label="Languages"
                type="text"
                helper={errors.languages?.message}
                {...register('languages')}
              />

              <div>
                <div className="flex justify-between items-center">
                  <h2 className="text-14 font-bold">Select Committee*</h2>
                  <button
                    onClick={() => setModalOpen2(true)}
                    className="text-13 bg-gray-100 px-4 py-2 rounded-md"
                  >
                    Select
                  </button>
                </div>

                {watch('committees').length > 0 ? (
                  watch('committees').map((committee) => (
                    <p key={committee} className="text-gray-600 text-15 pt-4">
                      {committee}
                    </p>
                  ))
                ) : (
                  <p className="text-gray-300">No options selected.</p>
                )}
              </div>

              <div className="flex space-x-2 pt-12">
                <Checkbox
                  className="rounded-full"
                  {...register('consentToExposure')}
                />
                <p className="text-14 text-gray-600">
                  Please check if you agree to sharing your email address,
                  location and practice area on the IAKL membership map
                  directory. You can always change your preference by visiting
                  'My Page' at any time.
                </p>
              </div>
            </>
          )}

          {me.type === UserType.STUDENT && (
            <>
              <div className="space-y-3">
                <TextField
                  label="Where did you study law?"
                  placeholder="Name of School"
                  helper={errors.school?.message}
                  {...register('school', {
                    required: 'This field is required',
                  })}
                />
                <TextField
                  placeholder="Class Year"
                  helper={errors.classYear?.message}
                  {...register('classYear', {
                    required: 'This field is required',
                  })}
                />
              </div>

              <TextField
                label="Mobile number "
                helper={errors.mobileNumber?.message}
                {...register('mobileNumber')}
              />

              <TextField
                label="Languages"
                helper={errors.languages?.message}
                {...register('languages')}
              />
            </>
          )}

          <div className="flex flex-col space-y-3 md:pt-14">
            <Button
              text="Request Account Deletion"
              className="outlined-brand-1"
              onClick={handleSubmit(async () => {
                await api.delete('/users/me').then(() => {
                  alert(
                    'Your account deletion request has been submitted successfully.',
                  );
                });
              })}
            />
            <Button
              text="Password Change"
              className="outlined-brand-1"
              onClick={() => push('/change-password')}
            />

            <Button
              text="Save"
              className="filled-brand-1"
              disabled={false}
              onClick={handleSubmit(async (data) => {
                await api.patch('/users/me', {
                  ...data,
                  salutation:
                    watch('salutation') === salutationOthers
                      ? watch('otherSalutation')
                      : watch('salutation'),
                });
                alert('Your profile has been updated');
              })}
            />
            <Button
              text="Logout"
              className="outlined-brand-1"
              onClick={logout}
            />
          </div>
        </div>
      </div>
    </>
  );
};

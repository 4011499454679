import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { MainPopup, Paginated, Seminar } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const MainPopupsList = () => {
  const { data: mainPopupsList } = useQuery<Paginated<MainPopup>>(
    `/admin/main-popups`,
    fetcher,
    { keepPreviousData: true },
  );

  if (!mainPopupsList) {
    return <></>;
  }
  return (
    <>
      <div className="flex justify-between">
        <AdminH1>MainPopups</AdminH1>
        <Button
          text="Add"
          to={`/admin/main/popups/add`}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>Title</Table.Th>
              <Table.Th>Created at</Table.Th>
              <Table.Th>PRIORITY</Table.Th>
              <Table.Th>Hide</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {mainPopupsList.items.map((mainPopups) => (
              <Table.Row key={mainPopups.id}>
                <Table.Td>{mainPopups.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(mainPopups.createdAt, MomentFormat.LLL)}
                </Table.Td>
                <Table.Td>{mainPopups.priority}</Table.Td>
                <Table.Td>{mainPopups.isHide ? '숨김' : '-'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/main/popups/${mainPopups.id}`}
                  >
                    Details
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={mainPopupsList?.items.length}
            total={mainPopupsList.total}
          />
          <Pagination.Nav
            basePath="/admin/main/popups"
            total={mainPopupsList.total}
          />
        </Pagination>
      </Card>
    </>
  );
};

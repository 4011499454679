import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthRoute } from '../components/AuthRoute';
import { Footer } from '../components/Footer';
import { MemberRoute } from '../components/MemberRoute';
import { TopNavBar } from '../components/TopNavBar';
import { languageState } from '../plugins/ridge';
import { postPaths } from '../post-config';
import { PasswordChangePage } from './PasswordChangePage';
import { CommitteesPage } from './CommitteesPage';
import { DirectoryPage } from './DirectoryPage';
import { GreetingPage } from './GreetingPage';
import { HistoryPage } from './HistoryPage';
import { HomePage } from './HomePage';
import { JoinUsOnePage } from './JoinUsOnePage';
import { JoinUsPendingPage } from './JoinUsPendingPage';
import { JoinUsStudentPage } from './JoinUsStudentPage';
import { JoinUsTwoPage } from './JoinUsTwoPage';
import { MyPage } from './MyPage';
import { NoticePage } from './NoticePage';
import { NoticesPage } from './NoticesPage';
import { OrganizationPage } from './OrganizationPage';
import { PostPage } from './PostPage';
import { PostsPage } from './PostsPage';
import { PrivacyPolicyPage } from './PrivacyPolicyPage';
import { ProBonoPage } from './ProBonoPage';
import { RelatedSitePage } from './RelatedSitePage';
import { SeminarsPage } from './SeminarsPage';
import { SeminarPage } from './SeminarPage';
import { SignInPage } from './SignInPage';
import { SponsorshipPage } from './SponsorshipPage';
import { WhoAreWePage } from './WhoAreWePage';
import { MainPopupModal } from '../components/\bMainModal';
import { SendResetPasswordEmailPage } from './SendResetPasswordEmailPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { MainPopup, Notice } from '../types';
import { GalleryPage } from './GalleryPage';

export const Router = () => {
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const { local } = parse(search, { ignoreQueryPrefix: true });
  const language = languageState.useValue();
  const [open, setOpen] = useState<boolean>(true);

  const { data: mainPopups } = useQuery<MainPopup[]>(`/main-popups`, fetcher);

  const publicLink =
    pathname === '/login' ||
    pathname === '/signin' ||
    pathname === '/find/password' ||
    pathname.startsWith('/reset/password') ||
    pathname === '/mypage' ||
    pathname === '/change-password' ||
    pathname.startsWith('/joinus');

  const koLink =
    pathname.startsWith('/greeting') ||
    pathname.startsWith('/history') ||
    pathname.startsWith('/organization') ||
    pathname.startsWith('/seminars') ||
    pathname.startsWith('/notices') ||
    pathname.startsWith('/related-sites');

  const enLink =
    pathname.startsWith('/who-are-we') ||
    pathname.startsWith('/recentnews') ||
    pathname.startsWith('/membership-directory') ||
    pathname.startsWith('/jobs') ||
    pathname.startsWith('/conferences') ||
    pathname.startsWith('/webinars') ||
    pathname.startsWith('/socialfunctions') ||
    pathname.startsWith('/pro-bono') ||
    pathname.startsWith('/committees') ||
    pathname.startsWith('/sponsorship') ||
    pathname.startsWith('/newsletters') ||
    pathname.startsWith('/publications') ||
    pathname.startsWith('/gallery');

  const adminLink = pathname.startsWith('/admin');

  useEffect(() => {
    if (local) {
      if (adminLink) {
        push('/admin');
      } else if (koLink && local !== 'KO') {
        push('/?local=EN');
      } else if (enLink && local !== 'EN') {
        push('/greeting?local=KO');
      } else {
        if (!koLink && local === 'KO') {
          push('/greeting?local=KO');
          languageState.set(local);
        } else if (!enLink && local === 'EN') {
          push('/?local=EN');
          languageState.set(local);
        } else {
          languageState.set(local);
        }
      }
    } else {
      if (!publicLink) {
        languageState.set('EN');
        push('/?local=EN');
      }
    }
  }, [enLink, koLink, language, local, pathname, publicLink, push]);

  return (
    <div className="flex flex-col min-h-screen">
      <TopNavBar />

      <main className="flex-1">
        <Route path="/" exact>
          {mainPopups && mainPopups.length !== 0 && (
            <MainPopupModal
              open={open}
              onClose={() => setOpen(false)}
              mainPopups={mainPopups}
            />
          )}
        </Route>

        <Switch>
          <Route path="/who-are-we" component={WhoAreWePage} />
          <MemberRoute
            path={postPaths.map((path) => `/${path}/:id`)}
            component={PostPage}
          />
          <Route path="/committees" component={CommitteesPage} />
          <MemberRoute
            path={postPaths.map((path) => `/${path}`)}
            component={PostsPage}
          />
          <MemberRoute path="/membership-directory" component={DirectoryPage} />

          <Route path="/pro-bono" component={ProBonoPage} />
          <Route path="/sponsorship" component={SponsorshipPage} />
          <AuthRoute path="/joinus/1" component={JoinUsOnePage} guestOnly />
          <Route path="/joinus/2" component={JoinUsTwoPage} />
          <Route path="/joinus/student" component={JoinUsStudentPage} />
          <AuthRoute path="/joinus/pending" component={JoinUsPendingPage} />
          <Route path="/notices/:id" component={NoticePage} />
          <Route path="/notices" component={NoticesPage} />
          <Route path="/seminars/:id" component={SeminarPage} />
          <Route path="/seminars" component={SeminarsPage} />
          <AuthRoute path="/signin" component={SignInPage} guestOnly />
          <AuthRoute
            path="/find/password"
            component={SendResetPasswordEmailPage}
            guestOnly
          />

          <AuthRoute
            path="/reset/password"
            component={ResetPasswordPage}
            guestOnly
          />
          <AuthRoute path="/change-password" component={PasswordChangePage} />

          <AuthRoute path="/mypage" component={MyPage} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/related-sites" component={RelatedSitePage} />
          <Route path="/organization" component={OrganizationPage} />
          <Route path="/history" component={HistoryPage} />
          <Route path="/greeting" component={GreetingPage} />
          <Route path="/" component={HomePage} exact />
          {/* <Route path="/">
            <Redirect to="/?local=EN" />
          </Route> */}
        </Switch>
      </main>

      <Footer />
    </div>
  );
};

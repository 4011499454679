import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import BottomImg from '../assets/img/main-bottomimg.png';
import BottomImg2 from '../assets/img/main-bottomimg2.png';
import BottomImg3 from '../assets/img/main-bottomimg3.png';
import BottomImg4 from '../assets/img/main-bottomimg4.png';
import BottomImg5 from '../assets/img/main-bottomimg5.png';
import { Section } from './Section';
import { Button } from './Button';

const data = [
  {
    id: 1,
    image: BottomImg,
    title: 'IAKL Committees',
    description: `Exchange specialist legal information and make international
                  contacts. Communicate and connect with fellow members
                  worldwide who share your interests and concerns in legal
                  practice. The IAKL Committees are where birds of a feather can
                  flock together, to grow and venture great distances together.
                  Exchange conversations, professional or casual, with like
                  minded people, translate theory into action, solve problems,
                  find solutions and look out for that lightbulb moment.`,
  },
  {
    id: 2,
    image: BottomImg2,
    title: 'IAKL Committees',
    description: `Exchange specialist legal information and make international
                  contacts. Communicate and connect with fellow members
                  worldwide who share your interests and concerns in legal
                  practice. The IAKL Committees are where birds of a feather can
                  flock together, to grow and venture great distances together.
                  Exchange conversations, professional or casual, with like
                  minded people, translate theory into action, solve problems,
                  find solutions and look out for that lightbulb moment.`,
  },
  {
    id: 3,
    image: BottomImg3,
    title: 'IAKL Committees',
    description: `Exchange specialist legal information and make international
                  contacts. Communicate and connect with fellow members
                  worldwide who share your interests and concerns in legal
                  practice. The IAKL Committees are where birds of a feather can
                  flock together, to grow and venture great distances together.
                  Exchange conversations, professional or casual, with like
                  minded people, translate theory into action, solve problems,
                  find solutions and look out for that lightbulb moment.`,
  },
  {
    id: 4,
    image: BottomImg4,
    title: 'IAKL Committees',
    description: `Exchange specialist legal information and make international
                  contacts. Communicate and connect with fellow members
                  worldwide who share your interests and concerns in legal
                  practice. The IAKL Committees are where birds of a feather can
                  flock together, to grow and venture great distances together.
                  Exchange conversations, professional or casual, with like
                  minded people, translate theory into action, solve problems,
                  find solutions and look out for that lightbulb moment.`,
  },
  {
    id: 5,
    image: BottomImg5,
    title: 'IAKL Committees',
    description: `Exchange specialist legal information and make international
                  contacts. Communicate and connect with fellow members
                  worldwide who share your interests and concerns in legal
                  practice. The IAKL Committees are where birds of a feather can
                  flock together, to grow and venture great distances together.
                  Exchange conversations, professional or casual, with like
                  minded people, translate theory into action, solve problems,
                  find solutions and look out for that lightbulb moment.`,
  },
];

export const MainSliderBottom = () => {
  return (
    <Carousel indicators={false}>
      {data.map((data) => (
        <Carousel.Item>
          <div
            className="relative flex flex-col w-full min-h-screen bg-center bg-cover"
            style={{ backgroundImage: `url('${data.image}')` }}
          >
            <Section className="relative flex-1 justify-center items-start w-full">
              <div className="max-w-xl">
                <h1 className="mb-6 font-bold text-32 text-white md:text-52">
                  {data.title}
                </h1>
                <p className="mb-9 text-gray-300 text-17">{data.description}</p>
                <Button
                  text="Join Now"
                  to="/joinus/1"
                  className="px-10 h-16 text-brand-1 absolute bg-white z-10"
                />
              </div>
            </Section>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { CommitteePopup } from '../components/CommitteePopup';
import { H1 } from '../components/H1';
import { PracticeAreaPopup } from '../components/PracticeAreaPopup';
import { Section } from '../components/Section';
import { Select } from '../components/Select';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { countries } from '../types';

interface FormValues {
  school: string;
  graduation: string;
  practiceAreas: string[];
  country: string;
  license: string;
  companyName: string;
  companyAddress: string;
  officeNumber: string;
  mobileNumber: string;
  languages: string;
  committees: string[];
  consentToExposure: boolean;
  disclaimer: boolean;
}

export const JoinUsTwoPage = () => {
  const { state } = useLocation<any>();
  const { push } = useHistory();
  const { signup } = useAuth();
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      country: countries[0].name,
      practiceAreas: [],
      committees: [],
    },
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  if (!state) return <Redirect to="/joinus/1" />;
  return (
    <form
      onSubmit={handleSubmit((data) => {
        setIsLoading(true);
        signup({ ...state, ...data })
          .then(() => {
            setIsLoading(false);
            push('/joinus/pending');
          })
          .catch(() => {
            setIsLoading(false);
          });
      })}
    >
      <PracticeAreaPopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        value={watch('practiceAreas')}
        onSave={(value) => setValue('practiceAreas', value)}
      />
      <CommitteePopup
        open={modalOpen2}
        onClose={() => setModalOpen2(false)}
        value={watch('committees')}
        onSave={(value) => setValue('committees', value)}
      />

      <Section className="max-w-md space-y-8">
        <div className="flex flex-col justify-center items-center space-y-6">
          <H1>Welcome!</H1>
          <button
            onClick={() => push('/joinus/student', state)}
            className="bg-gray-100 text-13 px-4 rounded-md py-2"
          >
            I am a student →
          </button>
          <p className="text-center text-gray-500">
            Pin-print your place on our global network
          </p>
        </div>

        <div className="flex flex-col space-y-3">
          <TextField
            label="Where did you study law?"
            placeholder="Name of School"
            helper={errors.school?.message}
            {...register('school', { required: 'This field is required' })}
          />
          <TextField
            placeholder="Year of Graduation"
            helper={errors.graduation?.message}
            {...register('graduation', { required: 'This field is required' })}
          />
        </div>

        <div>
          <div className="flex justify-between items-center">
            <h2 className="text-14 font-bold">Practice Area/Speciality*</h2>
            <button
              type="button"
              className="text-13 bg-gray-100 px-4 py-2 rounded-md"
              onClick={() => setModalOpen(true)}
            >
              Select
            </button>
          </div>

          {watch('practiceAreas').length > 0 ? (
            watch('practiceAreas').map((practiceArea) => (
              <p key={practiceArea} className="text-gray-600 text-15 pt-4">
                {practiceArea}
              </p>
            ))
          ) : (
            <p className="text-gray-300">No options selected.</p>
          )}
        </div>

        <Select label="Country/Jurisdiction*">
          {countries.map((country) => (
            <option key={country.name} value={country.name}>
              {country.name}
            </option>
          ))}
        </Select>

        <TextField
          label="License number*"
          helper={errors.license?.message}
          {...register('license', { required: 'This field is required' })}
        />

        <div className="flex flex-col space-y-3">
          <TextField
            label="Company/Law Firm*"
            placeholder="Name"
            helper={errors.companyName?.message}
            {...register('companyName', { required: 'This field is required' })}
          />
          <TextField
            placeholder="Address"
            helper={errors.companyAddress?.message}
            {...register('companyAddress', {
              required: 'This field is required',
            })}
          />
        </div>

        <TextField
          label="Office phone number*"
          helper={errors.officeNumber?.message}
          {...register('officeNumber', { required: 'This field is required' })}
        />

        <TextField label="Mobile number" {...register('mobileNumber')} />

        <TextField label="Languages" {...register('languages')} />

        <h1 className="text-24 font-bold text-center pt-14">
          Choose your Committee
        </h1>
        <p className="text-center text-gray-500 pb-8">
          Where birds of a feather flock together and
          <br /> venture great distances
        </p>

        <div>
          <div className="flex justify-between items-center">
            <h2 className="text-14 font-bold">Select Committee*</h2>
            <button
              type="button"
              onClick={() => setModalOpen2(true)}
              className="text-13 bg-gray-100 px-4 py-2 rounded-md"
            >
              Select
            </button>
          </div>

          {watch('committees').length > 0 ? (
            watch('committees').map((committee) => (
              <p key={committee} className="text-gray-600 text-15 pt-4">
                {committee}
              </p>
            ))
          ) : (
            <p className="text-gray-300">No options selected.</p>
          )}
        </div>

        <div className="flex space-x-2 pt-12">
          <Checkbox
            className="rounded-full"
            {...register('consentToExposure')}
          />
          <p className="text-14 text-gray-600">
            Please check if you agree to sharing your email address, location
            and practice area on the IAKL membership map directory. You can
            always change your preference by visiting 'My Page' at any time.
          </p>
        </div>
        <Button
          text={isLoading ? 'Loading...' : 'Join'}
          disabled={isLoading}
          className="w-full filled-brand-1"
        />
      </Section>
    </form>
  );
};

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table';
import { fetcher } from '../../plugins/react-query';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { Paginated, User } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { Search } from '../../components/Search';
import { Label } from '../../components/Label';
import * as XLSX from 'xlsx';
import { CSVLink } from 'react-csv';
import { Button } from '../../components/Button';

export const UsersList = () => {
  const [role, setRole] = useState('ALL');
  const [searchType, setSearchType] = useState('EMAIL');

  const [exposed, setExposed] = useState('ALL');
  const [searchData, setSearchData] = useState('');

  const filter = {
    role: role === 'ALL' ? undefined : role,
    exposed: exposed === 'ALL' ? undefined : exposed === 'EXPOSED',
    searchData: searchData,
    searchType: searchType,
  };

  const queryString = useQueryString({ filter });

  const { data } = useQuery<Paginated<User>>(
    `/admin/users/all/pagination${queryString}`,
    fetcher,
    { keepPreviousData: true },
  );

  const { data: AllData } = useQuery<User[]>(
    `/admin/users/all${queryString}`,
    fetcher,
    { keepPreviousData: true },
  );

  if (!data) return <></>;

  const exportToExcel = (allData: any) => {
    // excel 데이터 구성
    const excelData = [
      [
        'name',
        'email',
        'created_at',
        'role',
        'type',
        'salutation',
        'school',
        'graduation',
        'classYear',
        'license',
        'mobileNumber',
        'languages',
        'committees',
        'privacy',
        'consentToExposure',
        'disclaimer',
      ],
    ];

    allData?.map((user: any) => {
      const {
        firstName,
        lastName,
        email,
        createdAt,
        role,
        type,
        salutation,
        school,
        graduation,
        classYear,
        license,
        mobileNumber,
        languages,
        committees,
        privacy,
        consentToExposure,
        disclaimer,
      } = user;
      return excelData.push([
        firstName + lastName,
        email,
        createdAt,
        role,
        type,
        salutation,
        school,
        graduation,
        classYear,
        license,
        mobileNumber,
        languages,
        committees.length !== 0 ? committees.join(', ') : committees,
        privacy,
        consentToExposure,
        disclaimer,
      ]);
    });

    // 시트 생성
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // 워크북 생성
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'UsersData');

    // Excel 파일 생성
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Blob 생성
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    // 다운로드 링크 생성
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'UserData.xlsx');
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    } else {
      document.body.removeChild(link);
    }
  };
  return (
    <>
      <AdminH1>Users</AdminH1>

      <div className="flex space-x-4">
        <div className="w-40">
          <Select label="Role" onChange={(e) => setRole(e.target.value)}>
            <option label="ALL" value="ALL" />
            <option label="MEMBER" value="MEMBER" />
            <option label="NON_MEMBER" value="NON_MEMBER" />
            <option label="ADMIN" value="ADMIN" />
          </Select>
        </div>
        <div className="w-40">
          <Select
            label="SearchType"
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option label="EMAIL" value="EMAIL" />
            <option label="NAME" value="NAME" />
          </Select>
        </div>
        <div className="w-40">
          <Select label="Exposed" onChange={(e) => setExposed(e.target.value)}>
            <option label="ALL" value="ALL" />
            <option label="EXPOSED" value="EXPOSED" />
            <option label="NOT_EXPOSED" value="NOT_EXPOSED" />
          </Select>
        </div>
        <div className="w-80">
          <Label text="Search" />
          <Search
            placeholder="Search"
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
        {/* <CSVLink
          data={csvData}
          filename={'users.csv'}
          className="text-15 bg-gray-100 px-4 py-4"
        >
          Download
        </CSVLink> */}
        <div className="w-20 h-13 flex flex-1  justify-end items-end">
          <Button
            text="Download"
            className="text-15 bg-gray-100 px-4 py-2   text-center rounded-md hover:bg-gray-200"
            onClick={() => {
              exportToExcel(AllData);
            }}
          />
        </div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>name</Table.Th>
              <Table.Th>email</Table.Th>
              <Table.Th>created at</Table.Th>
              <Table.Th>role</Table.Th>
              <Table.Th>type</Table.Th>

              <Table.Th>exposed</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((user) => (
              <Table.Row key={user.id}>
                <Table.Td>
                  {user.firstName} {user.lastName}
                </Table.Td>
                <Table.Td>{user.email}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(user.createdAt, MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td>{user.role}</Table.Td>
                <Table.Td>{user.type}</Table.Td>

                <Table.Td>{user.exposed ? 'ON' : 'OFF'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/users/${user.id}`}
                  >
                    Details
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/users" total={data.total} />
        </Pagination>
      </Card>
    </>
  );
};

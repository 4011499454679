import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import {
  postHasContent,
  postHasDate,
  postPath,
  memberOnlyPost,
} from '../post-config';
import { Post, PostType, Role, User } from '../types';
import { Button } from './Button';
import { ClockIcon } from './icons';

export interface PostCardProps {
  post: Post;
  big?: boolean;
}

export const PostCard: FC<PostCardProps> = ({ post, big = false }) => {
  const { push } = useHistory();
  const { authenticated } = useAuth();

  async function accessDenied() {
    if (!memberOnlyPost(post.type)) return false;
    if (!authenticated) return true;
    const { data: me } = await api.get<User>('/users/me');
    return me.role === Role.NON_MEMBER;
  }

  return (
    <button
      className="flex flex-col text-left"
      onClick={async () => {
        if (await accessDenied()) return alert('Member Only');
        postHasContent(post.type)
          ? push(`/${postPath(post.type)}/${post.id}?local=EN`)
          : window.open(post.url, '_blank');
      }}
    >
      {post.thumbnail ? (
        <img
          src={post.thumbnail}
          alt={post.title}
          className={`mb-3 w-full rounded-lg object-cover bg-gray-200 ${
            post.type === PostType.WEBINAR
              ? big
                ? 'h-44'
                : 'h-32'
              : big
              ? 'h-64'
              : 'h-44'
          }`}
        />
      ) : (
        <div
          className={`grid place-items-center mb-3 w-full rounded-lg bg-gray-200 font-medium text-48 text-white ${
            post.type === PostType.WEBINAR ? 'h-32' : 'h-44'
          }`}
        >
          Image
        </div>
      )}

      <h1 className="flex-1 text-17 font-bold truncate-2-lines break-all">
        {post.title}
      </h1>
      <p className="flex space-x-1 pt-1 max-w-full text-13 text-gray-500">
        {postHasDate(post.type) ? (
          <>
            <ClockIcon className="wh-4" />
            <time className="flex-1 truncate">{post.dateString}</time>
          </>
        ) : (
          <time>{utcToLocalFormat(post.createdAt, MomentFormat.LL)}</time>
        )}
      </p>

      {post.type === PostType.WEBINAR && (
        <Button
          text="Watch Webinar"
          className="mt-4 w-full h-10 outlined-brand-1"
        />
      )}
    </button>
  );
};
